import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useUpdatingCallbacks } from 'use-updating-callbacks';
import { SearchWeather } from '../app/store';
import { AdventureId } from '../data-objects/adventures';
import {
  AT_BERGLABYRINTH_ID,
  AT_DASBERGVOLK_ID,
  AT_DIEVERLORENESTADT_ID,
  AT_EINRIESENKAMPF_ID,
  AT_UNBEKANNTEREGIONEN_ID
} from '../data-objects/adventures/Excelsior';
import { GlobalEffects } from '../data-objects/GlobalEffects';
import { useGlobalEffects } from '../redux/selectors';
import * as actions from '../redux/simulationActions';
import { BaseProps } from '../types/basics';
import SelectableElement from './Basics/SelectableElement';

const NoWeatherIcon = '/icons/buffs/NoWeather.png';
const FrostIcon = '/icons/buffs/icon_weather_condition_frost.png';
const SonnenscheinIcon = '/icons/buffs/icon_weather_condition_heat.png';
const WirbelsturmIcon = '/icons/buffs/icon_weather_condition_storm.png';
const NebelIcon = '/icons/buffs/icon_weather_condition_fog.png';

const ICON_RITUAL_WIND = '/icons/buffs/wind.jpg';
const ICON_RITUAL_STONE = '/icons/buffs/stone.jpg';
const ICON_RITUAL_WATER = '/icons/buffs/water.jpg';
const ICON_RITUAL_FIRE = '/icons/buffs/fire.jpg';
const ICON_RITUAL_METAL = '/icons/buffs/metal.jpg';
const ICON_RITUAL_NATURE = '/icons/buffs/nature.jpg';

const ICON_BK_COLD = '/icons/buffs/cold.png';
const ICON_BK_ICE = '/icons/buffs/ice.png';
const ICON_BK_STORM = '/icons/buffs/storm.png';
const ICON_BK_SNOW = '/icons/buffs/snow.png';
const ICON_BK_ARMORY = '/icons/buffs/armor.png';
const ICON_BK_HEADQUARTER = '/icons/buffs/head.png';
const ICON_BK_RECOVERY = '/icons/buffs/Suppe.png';
const ICON_BK_EP = '/icons/buffs/Wachturm.png';

const bergclan45 = [AT_DASBERGVOLK_ID, AT_EINRIESENKAMPF_ID];
const bergclan25 = [AT_UNBEKANNTEREGIONEN_ID, AT_BERGLABYRINTH_ID, ...bergclan45];
const lostCityId = AT_DIEVERLORENESTADT_ID;

interface GlobalEffectProps extends BaseProps {
  weatherEffects: GlobalEffects;
  selectedAdventure: AdventureId;
  toggleFrost: () => void;
  toggleWirbelsturm: () => void;
  toggleSonnenschein: () => void;
  toggleNebel: () => void;
  toggleCold: () => void;
  toggleIce: () => void;
  toggleStorm: () => void;
  toggleSnow: () => void;
  toggleArmory: () => void;
  toggleHeadquarter: () => void;
  toggleRecovery: () => void;
  toggleEp: () => void;
  toggleWind: () => void;
  toggleStone: () => void;
  toggleFire: () => void;
  toggleWater: () => void;
  toggleMetal: () => void;
  toggleNature: () => void;
  lazarett_basis_1: () => void;
  lazarett_basis_2: () => void;
  lazarett_basis_3: () => void;
  lazarett_enhanced_1: () => void;
  lazarett_enhanced_2: () => void;
  lazarett_enhanced_3: () => void;
  lazarett_premium_1: () => void;
  lazarett_premium_2: () => void;
  lazarett_premium_3: () => void;
  toggle_kantine_1: () => void;
  toggle_kantine_2: () => void;
  toggle_kantine_3: () => void;
  toggle_kantine_4: () => void;
  toggle_kantine_5: () => void;
  hideInfos?: true;
}
interface NoneEffectProps extends GlobalEffectProps {
  weatherEffects: SearchWeather;
  toggleNone: () => void;
}

const GlobalEffectSelectionStyled = styled(GlobalEffectSelectionUnstyled)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .padding-top {
    padding-top: 28px;
  }
  .wetterstation,
  .lazarett,
  .kantine,
  .bk-abenteuer-buffs,
  .lost-city-abenteuer-buffs {
    width: 100%;
    display: flex;
    flex-direction: column;

    .wetter-titel {
      font-weight: 800;
      padding-bottom: 8px;
    }

    .wetterbuffs {
      display: flex;
      flex-wrap: wrap;

      & > ${SelectableElement} {
        display: flex;
        flex-direction: column;
        font-size: 0.8em;
        padding: 2px;
        margin: auto 8px 8px 0;

        & > img {
          width: 35px;
        }

        & > * {
          margin: auto;
          text-align: center;
        }
      }
    }

    .wetter-infos {
      & > * {
        padding: 8px 0 0 8px;
      }
    }
  }
`;

export function GlobalEffectSelection(props: GlobalEffectProps | NoneEffectProps) {
  return <GlobalEffectSelectionStyled {...props} />;
}

function GlobalEffectSelectionUnstyled({
  weatherEffects,
  selectedAdventure,
  hideInfos,
  toggleFrost,
  toggleWirbelsturm,
  toggleSonnenschein,
  toggleNebel,
  toggleCold,
  toggleIce,
  toggleStorm,
  toggleSnow,
  toggleArmory,
  toggleHeadquarter,
  toggleRecovery,
  toggleEp,
  toggleWind,
  toggleStone,
  toggleFire,
  toggleWater,
  toggleMetal,
  toggleNature,
  ...otherProps
}: GlobalEffectProps | NoneEffectProps) {
  const { t } = useTranslation();

  const label = useMemo(
    () => ({
      wetter: t('Wetterstation'),
      none: t('Keins'),
      frost: t('Frost'),
      frostDetails: t('Frost-details'),
      wirbelsturm: t('Wirbelsturm'),
      wirbelsturmDetails: t('Wirbelsturm-details'),
      sonnenschein: t('Sonnenschein'),
      sonnenscheinDetails: t('Sonnenschein-details'),
      bergclan: t('Bergclan'),
      nebel: t('Nebel'),
      nebelDetails: t('Nebel-details'),
      cold: t('BK_Cold'),
      coldDetails: t('BK_Cold-details'),
      ice: t('BK_Ice'),
      iceDetails: t('BK_Ice-details'),
      storm: t('BK_Storm'),
      stormDetails: t('BK_Storm-details'),
      armory: t('BK_Armory'),
      armoryDetails: t('BK_Armory-details'),
      snow: t('BK_Snow'),
      snowDetails: t('BK_Snow-details'),
      headquarter: t('BK_Headquarter'),
      headquarterDetails: t('BK_Headquarter-details'),
      recovery: t('BK_Recovery'),
      recoveryDetails: t('BK_Recovery-details'),
      ep: t('BK_Ep'),
      epDetails: t('BK_Ep-details'),
      lostCity: t('TheLostCity'),
      wind: t('Wind'),
      windDetails: t('Wind-details'),
      stone: t('Stone'),
      stoneDetails: t('Stone-details'),
      fire: t('Fire'),
      fireDetails: t('Fire-details'),
      water: t('Water'),
      waterDetails: t('Water-details'),
      metal: t('Metal'),
      metalDetails: t('Metal-details'),
      nature: t('Nature'),
      natureDetails: t('Nature-details'),
      lazarett: t('lazarett'),
      lazarett_basis_1: t('lazarett_basis_1'),
      lazarett_basis_2: t('lazarett_basis_2'),
      lazarett_basis_3: t('lazarett_basis_3'),
      lazarett_enhanced_1: t('lazarett_enhanced_1'),
      lazarett_enhanced_2: t('lazarett_enhanced_2'),
      lazarett_enhanced_3: t('lazarett_enhanced_3'),
      lazarett_premium_1: t('lazarett_premium_1'),
      lazarett_premium_2: t('lazarett_premium_2'),
      lazarett_premium_3: t('lazarett_premium_3'),
      lazarett_basis_1_details: t('lazarett_basis_1-details'),
      lazarett_basis_2_details: t('lazarett_basis_2-details'),
      lazarett_basis_3_details: t('lazarett_basis_3-details'),
      lazarett_enhanced_1_details: t('lazarett_enhanced_1-details'),
      lazarett_enhanced_2_details: t('lazarett_enhanced_2-details'),
      lazarett_enhanced_3_details: t('lazarett_enhanced_3-details'),
      lazarett_premium_1_details: t('lazarett_premium_1-details'),
      lazarett_premium_2_details: t('lazarett_premium_2-details'),
      lazarett_premium_3_details: t('lazarett_premium_3-details'),
      kantine: t('kantine'),
      kantine_1: t('kantine_1'),
      kantine_2: t('kantine_2'),
      kantine_3: t('kantine_3'),
      kantine_4: t('kantine_4'),
      kantine_5: t('kantine_5'),
      kantine_1_details: t('kantine_hp-details', { hp: 4 }),
      kantine_2_details: t('kantine_hp-details', { hp: 8 }),
      kantine_3_details: t('kantine_attack-details', { hp: 12, attack: 4 }),
      kantine_4_details: t('kantine_attack-details', { hp: 16, attack: 8 }),
      kantine_5_details: t('kantine_attack-details', { hp: 20, attack: 12 })
    }),
    [t]
  );

  const styleProps = {
    className: otherProps.className,
    style: otherProps.style
  };

  const lostCity = lostCityId === selectedAdventure;
  const lazarett = ['basis', 'enhanced', 'premium'] as const;
  const lazarettValues = [1, 2, 3] as const;
  const { stufe: currentStufe, value: lazaretValue } = weatherEffects.lazarett ?? {};

  return (
    <div {...styleProps}>
      <div className='kantine'>
        <div className='wetter-titel'>{label.kantine}</div>
        <div className='wetterbuffs'>
          {([1, 2, 3, 4, 5] as const).flatMap(stufe => (
            <SelectableElement
              key={`kantine-${stufe}`}
              onClick={otherProps[`toggle_kantine_${stufe}`]}
              selected={stufe === weatherEffects.kantine}>
              <img draggable={false} src={`/icons/buffs/kantine_${stufe}.png`} alt='kantine' />
              <div>{label[`kantine_${stufe}`]}</div>
            </SelectableElement>
          ))}
        </div>
        {!hideInfos && (
          <div className='wetter-infos'>
            {weatherEffects.kantine && (
              <div className='details'>{label[`kantine_${weatherEffects.kantine}_details`]}</div>
            )}
          </div>
        )}
      </div>
      <div className='lazarett'>
        <div className='wetter-titel'>{label.lazarett}</div>
        <div className='wetterbuffs'>
          {lazarett.flatMap(stufe =>
            lazarettValues.map(value => {
              const weatherKey = `lazarett_${stufe}_${value}` as const;
              return (
                <SelectableElement
                  key={weatherKey}
                  onClick={otherProps[weatherKey]}
                  selected={stufe === currentStufe && value === lazaretValue}>
                  <img draggable={false} src={`/icons/buffs/${weatherKey}.png`} alt={weatherKey} />
                  <div>{label[weatherKey]}</div>
                </SelectableElement>
              );
            })
          )}
        </div>
        {!hideInfos && (
          <div className='wetter-infos'>
            {currentStufe === 'basis' && lazaretValue === 1 && (
              <div className='details'>{label.lazarett_basis_1_details}</div>
            )}
            {currentStufe === 'basis' && lazaretValue === 2 && (
              <div className='details'>{label.lazarett_basis_2_details}</div>
            )}
            {currentStufe === 'basis' && lazaretValue === 3 && (
              <div className='details'>{label.lazarett_basis_3_details}</div>
            )}
            {currentStufe === 'enhanced' && lazaretValue === 1 && (
              <div className='details'>{label.lazarett_enhanced_1_details}</div>
            )}
            {currentStufe === 'enhanced' && lazaretValue === 2 && (
              <div className='details'>{label.lazarett_enhanced_2_details}</div>
            )}
            {currentStufe === 'enhanced' && lazaretValue === 3 && (
              <div className='details'>{label.lazarett_enhanced_3_details}</div>
            )}
            {currentStufe === 'premium' && lazaretValue === 1 && (
              <div className='details'>{label.lazarett_premium_1_details}</div>
            )}
            {currentStufe === 'premium' && lazaretValue === 2 && (
              <div className='details'>{label.lazarett_premium_2_details}</div>
            )}
            {currentStufe === 'premium' && lazaretValue === 3 && (
              <div className='details'>{label.lazarett_premium_3_details}</div>
            )}
          </div>
        )}
      </div>
      <div className='wetterstation'>
        <div className='wetter-titel padding-top'>{label.wetter}</div>
        <div className='wetterbuffs'>
          {'toggleNone' in otherProps && (
            <SelectableElement
              onClick={otherProps.toggleNone}
              selected={'none' in weatherEffects && weatherEffects.none}>
              <img draggable={false} src={NoWeatherIcon} alt='no-buff' />
              <div>{label.none}</div>
            </SelectableElement>
          )}
          <SelectableElement onClick={toggleFrost} selected={weatherEffects.frost}>
            <img draggable={false} src={FrostIcon} alt='frost-buff' />
            <div>{label.frost}</div>
          </SelectableElement>
          <SelectableElement onClick={toggleWirbelsturm} selected={weatherEffects.wirbelsturm}>
            <img draggable={false} src={WirbelsturmIcon} alt='wirbelsturm-buff' />
            <div>{label.wirbelsturm}</div>
          </SelectableElement>
          <SelectableElement onClick={toggleSonnenschein} selected={weatherEffects.sonnenschein}>
            <img draggable={false} src={SonnenscheinIcon} alt='sonnenschein-buff' />
            <div>{label.sonnenschein}</div>
          </SelectableElement>
          <SelectableElement onClick={toggleNebel} selected={weatherEffects.nebel}>
            <img draggable={false} src={NebelIcon} alt='nebel-buff' />
            <div>{label.nebel}</div>
          </SelectableElement>
        </div>
        {!hideInfos && (
          <div className='wetter-infos'>
            {weatherEffects.frost && <div className='details'>{label.frostDetails}</div>}
            {weatherEffects.wirbelsturm && <div className='details'>{label.wirbelsturmDetails}</div>}
            {weatherEffects.sonnenschein && <div className='details'>{label.sonnenscheinDetails}</div>}
            {weatherEffects.nebel && <div className='details'>{label.nebelDetails}</div>}
          </div>
        )}
      </div>
      {bergclan25.includes(selectedAdventure) && (
        <div className='bk-abenteuer-buffs'>
          <div className='wetter-titel padding-top'>{label.bergclan}</div>
          <div className='wetterbuffs'>
            <SelectableElement onClick={toggleCold} selected={weatherEffects.bk_cold}>
              <img draggable={false} src={ICON_BK_COLD} alt='kaelte-buff' />
              <div>{label.cold}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleIce} selected={weatherEffects.bk_ice}>
              <img draggable={false} src={ICON_BK_ICE} alt='ice-buff' />
              <div>{label.ice}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleStorm} selected={weatherEffects.bk_storm}>
              <img draggable={false} src={ICON_BK_STORM} alt='storm-buff' />
              <div>{label.storm}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleSnow} selected={weatherEffects.bk_snow}>
              <img draggable={false} src={ICON_BK_SNOW} alt='snow-buff' />
              <div>{label.snow}</div>
            </SelectableElement>
            {bergclan45.includes(selectedAdventure) && (
              <>
                <SelectableElement onClick={toggleArmory} selected={weatherEffects.bk_armory}>
                  <img draggable={false} src={ICON_BK_ARMORY} alt='armory-buff' />
                  <div>{label.armory}</div>
                </SelectableElement>
                <SelectableElement onClick={toggleHeadquarter} selected={weatherEffects.bk_headquarter}>
                  <img draggable={false} src={ICON_BK_HEADQUARTER} alt='headquarter-buff' />
                  <div>{label.headquarter}</div>
                </SelectableElement>
                <SelectableElement onClick={toggleRecovery} selected={weatherEffects.bk_recovery}>
                  <img draggable={false} src={ICON_BK_RECOVERY} alt='recovery-buff' />
                  <div>{label.recovery}</div>
                </SelectableElement>
                <SelectableElement onClick={toggleEp} selected={weatherEffects.bk_ep}>
                  <img draggable={false} src={ICON_BK_EP} alt='ep-buff' />
                  <div>{label.ep}</div>
                </SelectableElement>
              </>
            )}
          </div>
          {!hideInfos && (
            <div className='wetter-infos'>
              {weatherEffects.bk_cold && <div className='details'>{label.coldDetails}</div>}
              {weatherEffects.bk_ice && <div className='details'>{label.iceDetails}</div>}
              {weatherEffects.bk_storm && <div className='details'>{label.stormDetails}</div>}
              {weatherEffects.bk_snow && <div className='details'>{label.snowDetails}</div>}
              {weatherEffects.bk_armory && <div className='details'>{label.armoryDetails}</div>}
              {weatherEffects.bk_headquarter && <div className='details'>{label.headquarterDetails}</div>}
              {weatherEffects.bk_recovery && <div className='details'>{label.recoveryDetails}</div>}
              {weatherEffects.bk_ep && <div className='details'>{label.epDetails}</div>}
            </div>
          )}
        </div>
      )}
      {lostCity && (
        <div className='lost-city-abenteuer-buffs'>
          <div className='wetter-titel padding-top'>{label.lostCity}</div>
          <div className='wetterbuffs'>
            <SelectableElement onClick={toggleWind} selected={weatherEffects.city_wind}>
              <img draggable={false} src={ICON_RITUAL_WIND} alt='wind-buff' />
              <div>{label.wind}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleStone} selected={weatherEffects.city_stone}>
              <img draggable={false} src={ICON_RITUAL_STONE} alt='stone-buff' />
              <div>{label.stone}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleFire} selected={weatherEffects.city_fire}>
              <img draggable={false} src={ICON_RITUAL_FIRE} alt='fire-buff' />
              <div>{label.fire}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleWater} selected={weatherEffects.city_water}>
              <img draggable={false} src={ICON_RITUAL_WATER} alt='water-buff' />
              <div>{label.water}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleMetal} selected={weatherEffects.city_metal}>
              <img draggable={false} src={ICON_RITUAL_METAL} alt='metal-buff' />
              <div>{label.metal}</div>
            </SelectableElement>
            <SelectableElement onClick={toggleNature} selected={weatherEffects.city_nature}>
              <img draggable={false} src={ICON_RITUAL_NATURE} alt='nature-buff' />
              <div>{label.nature}</div>
            </SelectableElement>
          </div>
          {!hideInfos && (
            <div className='wetter-infos'>
              {weatherEffects.city_wind && <div className='details'>{label.windDetails}</div>}
              {weatherEffects.city_stone && <div className='details'>{label.stoneDetails}</div>}
              {weatherEffects.city_fire && <div className='details'>{label.fireDetails}</div>}
              {weatherEffects.city_water && <div className='details'>{label.waterDetails}</div>}
              {weatherEffects.city_metal && <div className='details'>{label.metalDetails}</div>}
              {weatherEffects.city_nature && <div className='details'>{label.natureDetails}</div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

interface Props extends BaseProps {
  selectedAdventure: AdventureId;
}
function BuffSelection({ selectedAdventure, ...otherProps }: Props) {
  const dispatch = useDispatch();
  const weatherEffects = useGlobalEffects();

  const toggle = useUpdatingCallbacks({
    frost: () => dispatch(actions.toggleFrostWeather()),
    wirbel: () => dispatch(actions.toggleWirbelsturmWeather()),
    sonnenschein: () => dispatch(actions.toggleSonnenscheinWeather()),
    nebel: () => dispatch(actions.toggleNebelWeather()),
    cold: () => dispatch(actions.toggleColdEffect()),
    ice: () => dispatch(actions.toggleIceEffect()),
    storm: () => dispatch(actions.toggleStormEffect()),
    snow: () => dispatch(actions.toggleSnowEffect()),
    armory: () => dispatch(actions.toggleArmory()),
    headquarter: () => dispatch(actions.toggleHeadquarter()),
    recovery: () => dispatch(actions.toggleRecovery()),
    ep: () => dispatch(actions.toggleEp()),
    wind: () => dispatch(actions.toggleWind()),
    stone: () => dispatch(actions.toggleStone()),
    fire: () => dispatch(actions.toggleFire()),
    water: () => dispatch(actions.toggleWater()),
    metal: () => dispatch(actions.toggleMetal()),
    nature: () => dispatch(actions.toggleNature()),
    lazarett_basis_1: () => dispatch(actions.toggleLazarett('basis', 1)),
    lazarett_basis_2: () => dispatch(actions.toggleLazarett('basis', 2)),
    lazarett_basis_3: () => dispatch(actions.toggleLazarett('basis', 3)),
    lazarett_enhanced_1: () => dispatch(actions.toggleLazarett('enhanced', 1)),
    lazarett_enhanced_2: () => dispatch(actions.toggleLazarett('enhanced', 2)),
    lazarett_enhanced_3: () => dispatch(actions.toggleLazarett('enhanced', 3)),
    lazarett_premium_1: () => dispatch(actions.toggleLazarett('premium', 1)),
    lazarett_premium_2: () => dispatch(actions.toggleLazarett('premium', 2)),
    lazarett_premium_3: () => dispatch(actions.toggleLazarett('premium', 3)),
    toggle_kantine_1: () => dispatch(actions.toggleKantine(1)),
    toggle_kantine_2: () => dispatch(actions.toggleKantine(2)),
    toggle_kantine_3: () => dispatch(actions.toggleKantine(3)),
    toggle_kantine_4: () => dispatch(actions.toggleKantine(4)),
    toggle_kantine_5: () => dispatch(actions.toggleKantine(5))
  });

  return (
    <GlobalEffectSelection
      weatherEffects={weatherEffects}
      selectedAdventure={selectedAdventure}
      toggleFrost={toggle.frost}
      toggleNebel={toggle.nebel}
      toggleSonnenschein={toggle.sonnenschein}
      toggleWirbelsturm={toggle.wirbel}
      toggleCold={toggle.cold}
      toggleIce={toggle.ice}
      toggleStorm={toggle.storm}
      toggleSnow={toggle.snow}
      toggleArmory={toggle.armory}
      toggleHeadquarter={toggle.headquarter}
      toggleRecovery={toggle.recovery}
      toggleEp={toggle.ep}
      toggleWind={toggle.wind}
      toggleStone={toggle.stone}
      toggleFire={toggle.fire}
      toggleWater={toggle.water}
      toggleMetal={toggle.metal}
      toggleNature={toggle.nature}
      lazarett_basis_1={toggle.lazarett_basis_1}
      lazarett_basis_2={toggle.lazarett_basis_2}
      lazarett_basis_3={toggle.lazarett_basis_3}
      lazarett_enhanced_1={toggle.lazarett_enhanced_1}
      lazarett_enhanced_2={toggle.lazarett_enhanced_2}
      lazarett_enhanced_3={toggle.lazarett_enhanced_3}
      lazarett_premium_1={toggle.lazarett_premium_1}
      lazarett_premium_2={toggle.lazarett_premium_2}
      lazarett_premium_3={toggle.lazarett_premium_3}
      toggle_kantine_1={toggle.toggle_kantine_1}
      toggle_kantine_2={toggle.toggle_kantine_2}
      toggle_kantine_3={toggle.toggle_kantine_3}
      toggle_kantine_4={toggle.toggle_kantine_4}
      toggle_kantine_5={toggle.toggle_kantine_5}
      {...otherProps}
    />
  );
}

export default styled(BuffSelection)``;
