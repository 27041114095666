import { BaseUnit, UnitBaseType, GeneralType, SkillTree, UnitType, InitiativeType } from './BaseUnitTypes';

const UNIT_REKRUT_R_ICON = '/icons/units/icon_recruit.png';
const UNIT_MILIZ_M_ICON = '/icons/units/icon_militia.png';
const UNIT_REITEREI_C_ICON = '/icons/units/icon_cavalry.png';
const UNIT_SOLDAT_S_ICON = '/icons/units/icon_soldier.png';
const UNIT_ELITESOLDAT_ES_ICON = '/icons/units/icon_elite_soldier.png';
const UNIT_BOGENSCHÜTZE_B_ICON = '/icons/units/icon_bowman.png';
const UNIT_LANGBOGENSCHÜTZE_LB_ICON = '/icons/units/icon_longbowman.png';
const UNIT_ARMBRUSTSCHÜTZE_AB_ICON = '/icons/units/icon_crossbowman.png';
const UNIT_KANONIER_K_ICON = '/icons/units/icon_cannoneer.png';

const UNIT_SCHWERTKAEMPFER_SK_ICON = '/icons/units/swordsman.png';
const UNIT_BERITTENERSCHWERTKAEMPFER_BSK_ICON = '/icons/units/mounted_swordsman.png';
const UNIT_RITTER_RI_ICON = '/icons/units/knight.png';
const UNIT_SCHAERFSCHUETZE_SS_ICON = '/icons/units/marksman.png';
const UNIT_BERITTENERSCHARFSCHUETZE_BSS_ICON = '/icons/units/mounted_marksman.png';
const UNIT_GEPANZERTERSCHARFSCHUETZE_GSS_ICON = '/icons/units/armored_marksman.png';
const UNIT_BELAGERER_BL_ICON = '/icons/units/besieger.png';

// General icons
const kampfgestaehlterIcon = '/icons/generals/kampfgestaehlter.png';
const vmIcon = '/icons/generals/vm.png';
const klotzIcon = '/icons/generals/klotz.png';
const grosshelmIcon = '/icons/generals/grosshelm.png';
const mdkIcon = '/icons/generals/mdk.png';
const veteranIcon = '/icons/generals/vet.png';
const senseIcon = '/icons/generals/sense.png';
const dracuIcon = '/icons/generals/dracul.png';
const wissenschaftlerIcon = '/icons/generals/wissenschaftler.png';
const tavGenIcon = '/icons/generals/tav.png';
const anslemIcon = '/icons/generals/anslem.png';
const nusalaIcon = '/icons/generals/nusala.png';
const vargusIcon = '/icons/generals/vargus.png';
const grossmaulIcon = '/icons/generals/loudmouthgeneral.png';
const borisIcon = '/icons/generals/boris_icon.png';
const steadGen = '/icons/generals/standhafte_icon.png';
const vermummterGen = '/icons/generals/assassingeneral.png';
const jungGeminiIcon = '/icons/generals/jung_gemini.png';
const oldGeminiIcon = '/icons/generals/old_gemini.png';
const maryIcon = '/icons/generals/mary.png';
const fieldMedicIcon = '/icons/generals/field_medic_general.png';
const majorIcon = '/icons/generals/major_general_icon.png';
const zitterbartGen = '/icons/generals/zitterbart.png';
const geisterGenIcon = '/icons/generals/geistergen.png';
const frostGenIcon = '/icons/generals/frosti.png';
const lonerGenIcon = '/icons/generals/einzelgaenger.png';
const nussknackerGenIcon = '/icons/generals/nussknacker.png';
const miraculousGenIcon = '/icons/generals/mg.png';
const resoluteGenIcon = '/icons/generals/rg.png';

export const DEFAULT_GENERAL_ICON = tavGenIcon;

export const RECRUIT = 77;
export const ELITE_SOLDIER = 91;
export const MARKSMAN = 220;
export const BOWMAN = 78;
export const CAVALRY = 82;
export const MILITIA = 88;
export const SOLDIER = 89;
export const LONG_BOWMAN = 90;
export const CANNONEER = 92;
export const ARMORED_MARKSMAN = 222;
export const SWORDMAN = 223;
export const KNIGHT = 239;
export const CROSS_BOWMAN = 106;
export const BESIEGER = 235;
export const MOUNTED_MARKSMAN = 236;
export const MOUNTED_SWORDSMAN = 241;

export const TAV_GENERAL = 227;
export const MAD_SCIENTIST = 186;
export const MASTER_OF_DEFENSE = 218;
export const LORD_DRACUL = 219;
export const MARY_CHRISTMAS = 221;
export const CHAMPION_VARGUS = 224;
export const FIELD_MEDIC_GENERAL = 225;
export const OLDER_GEMINI_GENERAL = 226;
export const BATTLE_HARDENED_GENERAL = 228;
export const GENERAL_LOG = 229;
export const GENERAL_BIG_HELMED = 230;
export const VETERAN = 231;
export const MAJOR = 232;
export const GRIM_REAPER = 233;
export const GENERAL_BORIS = 237;
export const CHAMPION_ANSLEM = 238;
export const CHAMPION_NUSALA = 240;
export const MASTER_OF_MARTIAL_ARTS = 234;
export const JUNGER_GEMINI_GENERAL = 2226;
export const STEADFAST_GENERAL = 341;
export const VERMUMMTER_GENERAL = 342;
export const GENERAL_ZITTERBART = 343;
export const GEISTER_GENERAL = 344;
export const FROST_GENERAL = 345;
export const LONER_GENERAL = 346;
export const GENERAL_GROSSMAUL = 347;
export const NUSSKNACKER_GENERAL = 348;
export const MIRACULOUS_GENERAL = 349;
export const RESOLUTE_GENERAL = 350;

export enum Generals {
  TAV_GENERAL = 227,
  MAD_SCIENTIST = 186,
  MASTER_OF_DEFENSE = 218,
  LORD_DRACUL = 219,
  MARY_CHRISTMAS = 221,
  CHAMPION_VARGUS = 224,
  FIELD_MEDIC_GENERAL = 225,
  OLDER_GEMINI_GENERAL = 226,
  BATTLE_HARDENED_GENERAL = 228,
  GENERAL_LOG = 229,
  GENERAL_BIG_HELMED = 230,
  VETERAN = 231,
  MAJOR = 232,
  GRIM_REAPER = 233,
  GENERAL_BORIS = 237,
  CHAMPION_ANSLEM = 238,
  CHAMPION_NUSALA = 240,
  MASTER_OF_MARTIAL_ARTS = 234,
  JUNGER_GEMINI_GENERAL = 2226,
  STEADFAST_GENERAL = 341,
  VERMUMMTER_GENERAL = 342,
  GENERAL_ZITTERBART = 343,
  GEISTER_GENERAL = 344,
  FROST_GENERAL = 345,
  LONER_GENERAL = 346,
  GENERAL_GROSSMAUL = 347,
  NUSSKNACKER_GENERAL = 348,
  MIRACULOUS_GENERAL = 349,
  RESOLUTE_GENERAL = 350
}

export type UnitId = NormalUnitId | EliteUnitId;

export type NormalUnitId =
  | typeof RECRUIT
  | typeof BOWMAN
  | typeof MILITIA
  | typeof CAVALRY
  | typeof LONG_BOWMAN
  | typeof SOLDIER
  | typeof CROSS_BOWMAN
  | typeof ELITE_SOLDIER
  | typeof CANNONEER;

export type EliteUnitId =
  | typeof SWORDMAN
  | typeof MOUNTED_SWORDSMAN
  | typeof KNIGHT
  | typeof MARKSMAN
  | typeof ARMORED_MARKSMAN
  | typeof MOUNTED_MARKSMAN
  | typeof BESIEGER;

export type GeneralId =
  | typeof TAV_GENERAL
  | typeof MAD_SCIENTIST
  | typeof MASTER_OF_DEFENSE
  | typeof LORD_DRACUL
  | typeof MARY_CHRISTMAS
  | typeof CHAMPION_VARGUS
  | typeof GENERAL_GROSSMAUL
  | typeof FIELD_MEDIC_GENERAL
  | typeof OLDER_GEMINI_GENERAL
  | typeof BATTLE_HARDENED_GENERAL
  | typeof GENERAL_LOG
  | typeof GENERAL_BIG_HELMED
  | typeof VETERAN
  | typeof MAJOR
  | typeof GRIM_REAPER
  | typeof GENERAL_BORIS
  | typeof CHAMPION_ANSLEM
  | typeof CHAMPION_NUSALA
  | typeof MASTER_OF_MARTIAL_ARTS
  | typeof JUNGER_GEMINI_GENERAL
  | typeof STEADFAST_GENERAL
  | typeof VERMUMMTER_GENERAL
  | typeof GEISTER_GENERAL
  | typeof FROST_GENERAL
  | typeof LONER_GENERAL
  | typeof NUSSKNACKER_GENERAL
  | typeof MIRACULOUS_GENERAL
  | typeof RESOLUTE_GENERAL
  | typeof GENERAL_ZITTERBART;

export interface Unit extends BaseUnit {
  id: EliteUnitId | NormalUnitId;
  elite?: boolean;
  buildingBonus?: number;
  type: UnitBaseType;
  rang: number;
}

export interface General extends BaseUnit {
  id: GeneralId;
  uuid?: string;
  icon: string;
  capacity: number;
  skills?: SkillTree | null;
  bonusExp?: number;
  bonusHeavy?: number;
  bonusOff?: number;
  bonusDef?: number;
  vargus?: number;
  suppressFlank?: boolean;
  timePerRound?: number;
  kampfrausch?: number;
  bonusHp?: number;
  enemyAccuracyMalus?: number;
  type: GeneralType.GENERAL;
}

export type OldGeneral =
  | typeof TavGeneral
  | typeof MasterOfDefense
  | typeof MasterOfMartialArts
  | typeof MadScientist
  | typeof LordDracul
  | typeof GeneralMaryChristmas
  | typeof ChampionVargus
  | typeof FieldMedicGeneral
  | typeof OlderGeminiGeneral
  | typeof BattleHardenedGeneral
  | typeof GeneralLog
  | typeof GeneralBighelmet
  | typeof Veteran
  | typeof Major
  | typeof GrimReaper
  | typeof GeneralBoris
  | typeof ChampionAnslem
  | typeof ChampionNusala
  | typeof JungerGeminiGeneral
  | typeof SteadfastGeneral
  | typeof VermummterGeneral
  | typeof FrostGeneral
  | typeof ResoluteGeneral
  | typeof GeneralZitterbart;

export type ArmyId = UnitId | GeneralId;

interface InternalBaseType {
  name: string;
  abr: string;
  hp: number;
  min: number;
  max: number;
  accuracy: number;
  icon: string;
  flank?: boolean;
  splash?: number;
  recovery?: number;
  initiative?: InitiativeType;
}

interface InternalUnit extends InternalBaseType {
  id: EliteUnitId | NormalUnitId;
  elite?: boolean;
  type: UnitBaseType;
  rang: number;
  buildingBonus?: number;
}

interface InternalGeneral extends InternalBaseType {
  id: Generals;
  type: GeneralType.GENERAL;
  capacity: number;
  timePerRound?: number;
  bonusHp?: number;
  enemyAccuracyMalus?: number;
  bonusExp?: number;
  bonusHeavy?: number;
  bonusOff?: number;
  bonusDef?: number;
  vargus?: number;
  suppressFlank?: boolean;
  kampfrausch?: number;
}

const Recruit: InternalUnit = {
  name: 'Recruit',
  abr: 'R',
  rang: 1,
  //ep: 2,
  hp: 40,
  min: 15,
  max: 30,
  accuracy: 80,
  id: RECRUIT,
  icon: UNIT_REKRUT_R_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF
};

const Militia: InternalUnit = {
  name: 'Militia',
  abr: 'M',
  rang: 2,
  //ep: 9,
  hp: 60,
  min: 20,
  max: 40,
  accuracy: 80,
  id: MILITIA,
  icon: UNIT_MILIZ_M_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF
};

const Soldier: InternalUnit = {
  name: 'Soldier',
  abr: 'S',
  rang: 3,
  //ep: 6,
  hp: 90,
  min: 20,
  max: 40,
  accuracy: 85,
  id: SOLDIER,
  icon: UNIT_SOLDAT_S_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF
};

const EliteSoldier: InternalUnit = {
  name: 'Elite Soldier',
  abr: 'E',
  rang: 4,
  //ep: 20,
  hp: 120,
  min: 20,
  max: 40,
  accuracy: 90,
  id: ELITE_SOLDIER,
  icon: UNIT_ELITESOLDAT_ES_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF
};

const Cavalry: InternalUnit = {
  name: 'Cavalry',
  abr: 'C',
  rang: 5,
  //ep: 8,
  hp: 5,
  min: 5,
  max: 10,
  accuracy: 80,
  id: CAVALRY,
  icon: UNIT_REITEREI_C_ICON,
  flank: true,
  initiative: InitiativeType.FIRST,
  type: UnitBaseType.SWIFT
};

const Bowman: InternalUnit = {
  name: 'Bowman',
  abr: 'B',
  rang: 6,
  //ep: 3,
  hp: 10,
  min: 20,
  max: 40,
  accuracy: 80,
  id: BOWMAN,
  icon: UNIT_BOGENSCHÜTZE_B_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.OFF
};

const Longbowman: InternalUnit = {
  name: 'Longbowman',
  abr: 'LB',
  rang: 7,
  //ep: 20,
  hp: 10,
  min: 30,
  max: 60,
  accuracy: 80,
  id: LONG_BOWMAN,
  icon: UNIT_LANGBOGENSCHÜTZE_LB_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.OFF
};

const Crossbowman: InternalUnit = {
  name: 'Crossbowman',
  abr: 'AB',
  rang: 8,
  //ep: 20,
  hp: 10,
  min: 45,
  max: 90,
  accuracy: 80,
  id: CROSS_BOWMAN,
  icon: UNIT_ARMBRUSTSCHÜTZE_AB_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.OFF
};

const Cannoneer: InternalUnit = {
  name: 'Cannoneer',
  abr: 'K',
  rang: 9,
  //ep: 30,
  hp: 60,
  min: 60,
  max: 120,
  accuracy: 90,
  id: CANNONEER,
  buildingBonus: 1,
  icon: UNIT_KANONIER_K_ICON,
  initiative: InitiativeType.LAST,
  type: UnitBaseType.HEAVY
};

const Swordsman: InternalUnit = {
  name: 'Swordsman',
  abr: 'SK',
  rang: 10,
  //ep: 49,
  hp: 150,
  min: 40,
  max: 80,
  accuracy: 90,
  id: SWORDMAN,
  icon: UNIT_SCHWERTKAEMPFER_SK_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF,
  elite: true
};

const MountedSwordsman: InternalUnit = {
  name: 'Mounted Swordsman',
  abr: 'BSK',
  rang: 11,
  //ep: 66,
  hp: 250,
  min: 25,
  max: 50,
  accuracy: 90,
  id: MOUNTED_SWORDSMAN,
  icon: UNIT_BERITTENERSCHWERTKAEMPFER_BSK_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.DEF,
  elite: true
};

const Knight: InternalUnit = {
  name: 'Knight',
  abr: 'Ri',
  rang: 12,
  //ep: 18,
  hp: 80,
  min: 35,
  max: 70,
  accuracy: 90,
  id: KNIGHT,
  icon: UNIT_RITTER_RI_ICON,
  initiative: InitiativeType.FIRST,
  type: UnitBaseType.SWIFT,
  elite: true
};

const Marksman: InternalUnit = {
  name: 'Marksman',
  abr: 'SSch',
  rang: 13,
  //ep: 21,
  hp: 50,
  min: 50,
  max: 100,
  accuracy: 90,
  id: MARKSMAN,
  icon: UNIT_SCHAERFSCHUETZE_SS_ICON,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.OFF,
  elite: true
};

const ArmoredMarksman: InternalUnit = {
  name: 'ArmoredMarksman',
  abr: 'GSS',
  rang: 14,
  //ep: 69,
  hp: 50,
  min: 100,
  max: 200,
  accuracy: 90,
  id: ARMORED_MARKSMAN,
  icon: UNIT_GEPANZERTERSCHARFSCHUETZE_GSS_ICON,
  flank: true,
  initiative: InitiativeType.NORMAL,
  type: UnitBaseType.OFF,
  elite: true
};

const MountedMarksman: InternalUnit = {
  name: 'Mounted Marksman',
  abr: 'BSS',
  rang: 15,
  //ep: 38,
  hp: 50,
  min: 50,
  max: 100,
  accuracy: 90,
  id: MOUNTED_MARKSMAN,
  icon: UNIT_BERITTENERSCHARFSCHUETZE_BSS_ICON,
  initiative: InitiativeType.FIRST,
  type: UnitBaseType.SWIFT,
  elite: true,
  splash: 1
};

const Besieger: InternalUnit = {
  name: 'Besieger',
  abr: 'BL',
  rang: 16,
  //ep: 70,
  hp: 80,
  min: 100,
  max: 200,
  accuracy: 90,
  id: BESIEGER,
  icon: UNIT_BELAGERER_BL_ICON,
  initiative: InitiativeType.LAST,
  type: UnitBaseType.HEAVY,
  elite: true,
  splash: 1,
  buildingBonus: 1
};

/*************************************************************************************
 * Generals
 */
const MasterOfDefense: InternalGeneral = {
  name: 'Master Of Defense',
  abr: 'VM',
  hp: 5000,

  min: 25,
  max: 50,
  accuracy: 80,

  id: MASTER_OF_DEFENSE,
  icon: vmIcon,
  capacity: 200,
  flank: true,
  splash: 1,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const LordDracul: InternalGeneral = {
  name: 'Lord Dracul',
  abr: 'Dracu',
  hp: 999,

  min: 111,
  max: 333,
  accuracy: 77,

  id: LORD_DRACUL,
  capacity: 200,
  icon: dracuIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  splash: 1
};

const GeneralMaryChristmas: InternalGeneral = {
  name: 'General Mary Christmas',
  abr: 'Mary',
  hp: 100,
  min: 125,
  max: 250,
  accuracy: 80,
  id: MARY_CHRISTMAS,
  capacity: 200,
  icon: maryIcon,
  bonusExp: 100, // Increases XP gained from enemy units defeated by this army by 100%.,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const ChampionVargus: InternalGeneral = {
  name: 'Champion Vargus',
  abr: 'Var',
  hp: 200,
  min: 750,
  max: 1250,
  accuracy: 80,
  id: CHAMPION_VARGUS,
  capacity: 180,
  icon: vargusIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  splash: 1,
  suppressFlank: true,
  vargus: 5
};

const GeneralGrossmaul: InternalGeneral = {
  name: 'General Großmaul',
  abr: 'GGm',
  hp: 300,
  min: 800,
  max: 1100,
  accuracy: 80,
  id: GENERAL_GROSSMAUL,
  capacity: 170,
  icon: grossmaulIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  vargus: 10
};

const FieldMedicGeneral: InternalGeneral = {
  name: 'Field Medic General',
  abr: 'Medic',
  hp: 100,

  min: 120,
  max: 120,
  accuracy: 80,

  id: FIELD_MEDIC_GENERAL,
  capacity: 200,
  icon: fieldMedicIcon,
  recovery: 30, // Recovers 30% of lost units after every battle.,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL,
  splash: 1
};

const MadScientist: InternalGeneral = {
  name: 'Mad Scientist',
  abr: 'Wissen',
  hp: 100,
  min: 300,
  max: 600,
  accuracy: 60,
  id: MAD_SCIENTIST,
  capacity: 200,
  icon: wissenschaftlerIcon,
  splash: 1,
  bonusHeavy: 100,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const JungerGeminiGeneral: InternalGeneral = {
  name: 'Junger Gemini General',
  abr: 'JZ',
  hp: 2000,
  min: 4500,
  max: 5000,
  accuracy: 90,
  capacity: 140,
  id: JUNGER_GEMINI_GENERAL,
  icon: jungGeminiIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL,
  flank: true,
  splash: 1
};

const OlderGeminiGeneral: InternalGeneral = {
  name: 'Older Gemini General',
  abr: 'AZ',
  hp: 100,
  min: 125,
  max: 250,
  accuracy: 80,
  capacity: 280,
  id: OLDER_GEMINI_GENERAL,
  icon: oldGeminiIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  flank: true,
  splash: 1,
  recovery: 20 // Recover 20% of lost units after every battle.,
};

const TavGeneral: InternalGeneral = {
  name: 'General',
  abr: 'Tav',
  timePerRound: 20,
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: TAV_GENERAL,
  capacity: 200,
  icon: tavGenIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const BattleHardenedGeneral: InternalGeneral = {
  name: 'BattleHardenedGeneral',
  abr: 'KG',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: BATTLE_HARDENED_GENERAL,
  capacity: 200,
  icon: kampfgestaehlterIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const GeneralLog: InternalGeneral = {
  name: 'General Log',
  abr: 'Klotz',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: GENERAL_LOG,
  capacity: 220,
  icon: klotzIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const GeneralBighelmet: InternalGeneral = {
  name: 'General Bighelmet',
  abr: 'big',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: GENERAL_BIG_HELMED,
  capacity: 200,
  icon: grosshelmIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const Veteran: InternalGeneral = {
  name: 'Veteran',
  abr: 'Vet',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: VETERAN,
  capacity: 250,
  icon: veteranIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const Major: InternalGeneral = {
  name: 'Major',
  abr: 'GM',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: MAJOR,
  capacity: 270,
  icon: majorIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const GrimReaper: InternalGeneral = {
  name: 'Grim Reaper',
  abr: 'Sense',
  hp: 1,

  min: 120,
  max: 120,
  accuracy: 80,

  id: GRIM_REAPER,
  capacity: 200,
  icon: senseIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL
};

const MasterOfMartialArts: InternalGeneral = {
  name: 'Master Of Martial Arts',
  abr: 'MdK',
  hp: 1,

  min: 450,
  max: 500,
  accuracy: 80,

  id: MASTER_OF_MARTIAL_ARTS,
  capacity: 220,
  icon: mdkIcon,
  flank: true,
  splash: 1,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL
};

const GeneralBoris: InternalGeneral = {
  name: 'General Boris',
  abr: 'Bor',
  hp: 100,

  min: 700,
  max: 1000,
  accuracy: 80,

  id: GENERAL_BORIS,
  capacity: 180,
  icon: borisIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  flank: true,
  splash: 1,
  bonusExp: 50
};

const ChampionAnslem: InternalGeneral = {
  name: 'Champion Anslem',
  abr: 'Ans',
  hp: 150,

  min: 1000,
  max: 1500,
  accuracy: 50,

  id: CHAMPION_ANSLEM,
  capacity: 150,
  icon: anslemIcon,
  flank: true,
  splash: 1,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL,
  enemyAccuracyMalus: 100
};

const ChampionNusala: InternalGeneral = {
  name: 'Champion Nusala',
  abr: 'Nus',
  hp: 150,
  min: 1000,
  max: 1500,
  accuracy: 90,
  id: CHAMPION_NUSALA,
  capacity: 165,
  icon: nusalaIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  flank: true,
  splash: 1
};

const SteadfastGeneral: InternalGeneral = {
  name: 'Steadfast General',
  abr: 'StG',
  hp: 10000,
  min: 100,
  max: 200,
  accuracy: 70,
  id: STEADFAST_GENERAL,
  capacity: 220,
  icon: steadGen,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL,
  flank: true,
  splash: 1
};

const VermummterGeneral: InternalGeneral = {
  name: 'Vermummter General',
  abr: 'VG',
  hp: 1000,
  timePerRound: 7,
  min: 500,
  max: 5000,
  accuracy: 10,
  id: VERMUMMTER_GENERAL,
  capacity: 200,
  icon: vermummterGen,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  splash: 1
};

const GeneralZitterbart: InternalGeneral = {
  name: 'General Zitterbart',
  abr: 'GZ',
  hp: 1,
  min: 375,
  max: 425,
  accuracy: 90,
  id: GENERAL_ZITTERBART,
  capacity: 160,
  icon: zitterbartGen,
  bonusExp: 200,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  splash: 1,
  flank: true
};

const Geistergeneral: InternalGeneral = {
  name: 'Geistergeneralin',
  abr: 'Geist',
  hp: 2,
  min: 777,
  max: 999,
  accuracy: 85,
  id: GEISTER_GENERAL,
  capacity: 215,
  timePerRound: 7,
  icon: geisterGenIcon,
  initiative: InitiativeType.LAST,
  type: GeneralType.GENERAL,
  splash: 1,
  flank: true,
  recovery: 20,
  bonusHp: 20,
  enemyAccuracyMalus: 50
};

const FrostGeneral: InternalGeneral = {
  name: 'Frostiger General',
  abr: 'Frost',
  hp: 2412,
  min: 2412,
  max: 2612,
  accuracy: 24,
  id: Generals.FROST_GENERAL,
  capacity: 190,
  icon: frostGenIcon,
  initiative: InitiativeType.NORMAL,
  type: GeneralType.GENERAL,
  splash: 1,
  bonusDef: 30
};

const LonerGeneral: InternalGeneral = {
  name: 'Einzelgänger General',
  abr: 'EG',
  hp: 500,
  min: 500,
  max: 2000,
  accuracy: 95,
  id: Generals.LONER_GENERAL,
  capacity: 230,
  icon: lonerGenIcon,
  initiative: InitiativeType.FIRST,
  type: GeneralType.GENERAL,
  recovery: 10,
  splash: 1,
  bonusOff: 50
};

const NussknackerGeneral: InternalGeneral = {
  name: 'Nussknacker General',
  abr: 'Nuss',
  hp: 3000,
  min: 500,
  max: 2000,
  accuracy: 70,
  id: Generals.NUSSKNACKER_GENERAL,
  capacity: 200,
  icon: nussknackerGenIcon,
  type: GeneralType.GENERAL,
  flank: true,
  recovery: 20,
  bonusHp: 30
};

const MiraculousGeneral: InternalGeneral = {
  name: 'Miraculous General',
  abr: 'MG',
  hp: 300,
  min: 400,
  max: 800,
  accuracy: 80,
  initiative: InitiativeType.LAST,
  id: Generals.MIRACULOUS_GENERAL,
  capacity: 200,
  icon: miraculousGenIcon,
  type: GeneralType.GENERAL,
  recovery: 45
};

const ResoluteGeneral: InternalGeneral = {
  name: 'Resolute General',
  abr: 'RG',
  hp: 3000,
  min: 400,
  max: 600,
  accuracy: 85,
  initiative: InitiativeType.NORMAL,
  id: Generals.RESOLUTE_GENERAL,
  capacity: 200,
  icon: resoluteGenIcon,
  type: GeneralType.GENERAL,
  kampfrausch: 50,
  suppressFlank: true,
  splash: 1
};

export function getUnitById(id: GeneralId): General;
export function getUnitById(id: UnitId): Unit;
export function getUnitById(id: ArmyId): Unit | General;
export function getUnitById(id: ArmyId): Unit | General {
  const intId = id;
  let unit;
  switch (intId) {
    case RECRUIT:
      unit = Recruit;
      break;
    case MASTER_OF_DEFENSE:
      unit = MasterOfDefense;
      break;
    case ELITE_SOLDIER:
      unit = EliteSoldier;
      break;
    case MARKSMAN:
      unit = Marksman;
      break;
    case MAD_SCIENTIST:
      unit = MadScientist;
      break;
    case BOWMAN:
      unit = Bowman;
      break;
    case CAVALRY:
      unit = Cavalry;
      break;
    case MILITIA:
      unit = Militia;
      break;
    case SOLDIER:
      unit = Soldier;
      break;
    case LONG_BOWMAN:
      unit = Longbowman;
      break;
    case LORD_DRACUL:
      unit = LordDracul;
      break;
    case CANNONEER:
      unit = Cannoneer;
      break;
    case MARY_CHRISTMAS:
      unit = GeneralMaryChristmas;
      break;
    case ARMORED_MARKSMAN:
      unit = ArmoredMarksman;
      break;
    case SWORDMAN:
      unit = Swordsman;
      break;
    case CHAMPION_VARGUS:
      unit = ChampionVargus;
      break;
    case GENERAL_GROSSMAUL:
      unit = GeneralGrossmaul;
      break;
    case FIELD_MEDIC_GENERAL:
      unit = FieldMedicGeneral;
      break;
    case OLDER_GEMINI_GENERAL:
      unit = OlderGeminiGeneral;
      break;
    case TAV_GENERAL:
      unit = TavGeneral;
      break;
    case BATTLE_HARDENED_GENERAL:
      unit = BattleHardenedGeneral;
      break;
    case GENERAL_LOG:
      unit = GeneralLog;
      break;
    case GENERAL_BIG_HELMED:
      unit = GeneralBighelmet;
      break;
    case VETERAN:
      unit = Veteran;
      break;
    case MAJOR:
      unit = Major;
      break;
    case GRIM_REAPER:
      unit = GrimReaper;
      break;
    case CROSS_BOWMAN:
      unit = Crossbowman;
      break;
    case BESIEGER:
      unit = Besieger;
      break;
    case MOUNTED_MARKSMAN:
      unit = MountedMarksman;
      break;
    case GENERAL_BORIS:
      unit = GeneralBoris;
      break;
    case CHAMPION_ANSLEM:
      unit = ChampionAnslem;
      break;
    case KNIGHT:
      unit = Knight;
      break;
    case CHAMPION_NUSALA:
      unit = ChampionNusala;
      break;
    case MOUNTED_SWORDSMAN:
      unit = MountedSwordsman;
      break;
    case MASTER_OF_MARTIAL_ARTS:
      unit = MasterOfMartialArts;
      break;
    case JUNGER_GEMINI_GENERAL:
      unit = JungerGeminiGeneral;
      break;
    case STEADFAST_GENERAL:
      unit = SteadfastGeneral;
      break;
    case VERMUMMTER_GENERAL:
      unit = VermummterGeneral;
      break;
    case GENERAL_ZITTERBART:
      unit = GeneralZitterbart;
      break;
    case GEISTER_GENERAL:
      unit = Geistergeneral;
      break;
    case FROST_GENERAL:
      unit = FrostGeneral;
      break;
    case LONER_GENERAL:
      unit = LonerGeneral;
      break;
    case NUSSKNACKER_GENERAL:
      unit = NussknackerGeneral;
      break;
    case RESOLUTE_GENERAL:
      unit = ResoluteGeneral;
      break;
    case Generals.MIRACULOUS_GENERAL:
      unit = MiraculousGeneral;
      break;
    default:
      throw new Error('Unexpected unit id');
  }
  return {
    ...unit,
    hpLeft: unit.hp,
    base: {
      hp: unit.hp,
      min: unit.min,
      max: unit.max,
      accuracy: unit.accuracy
    }
  };
}

export function getUnitByExpression(
  expr: (unit: InternalUnit | InternalGeneral) => unit is Unit | General = (unit): unit is Unit | General => true
): (Unit | General)[] {
  const units = [
    Recruit,
    Bowman,
    Militia,
    Cavalry,
    Longbowman,
    Soldier,
    Crossbowman,
    EliteSoldier,
    Cannoneer,
    Swordsman,
    MountedSwordsman,
    Knight,
    Marksman,
    ArmoredMarksman,
    MountedMarksman,
    Besieger,
    TavGeneral,
    GeneralLog,
    BattleHardenedGeneral,
    Veteran,
    Major,
    MasterOfMartialArts,
    LordDracul,
    MasterOfDefense,
    MadScientist,
    GeneralMaryChristmas,
    FieldMedicGeneral,
    ChampionVargus,
    ChampionAnslem,
    ChampionNusala,
    GeneralBoris,
    OlderGeminiGeneral,
    JungerGeminiGeneral,
    SteadfastGeneral,
    VermummterGeneral,
    GeneralZitterbart,
    Geistergeneral,
    FrostGeneral,
    LonerGeneral,
    GeneralGrossmaul,
    NussknackerGeneral,
    MiraculousGeneral,
    ResoluteGeneral
  ];
  return units.filter(expr).map(unit => ({
    ...unit,
    hpLeft: unit.hpLeft,
    base: {
      hp: unit.hp,
      accuracy: unit.accuracy,
      min: unit.min,
      max: unit.max
    }
  }));
}

export function getUnitByType(type: UnitType): (Unit | General)[] {
  return getUnitByExpression((unit): unit is Unit | General => unit.type === type);
}

export function getIconByUnitId(id: ArmyId): string {
  return getUnitById(id).icon;
}
